/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('.scrollup').fadeIn();
                } else {
                    $('.scrollup').fadeOut();
                }
            });
            $('.scrollup').click(function () {
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
                return false;
            });
        });
        var screenSize = 'lg';

        function checkSize() {
          if($('.test-size').css('font-size') === '1px') {
            screenSize = 'xs';
          } else if($('.test-size').css('font-size') === '2px') {
            screenSize = 'sm';
          } else if($('.test-size').css('font-size') === '3px') {
            screenSize = 'md';
          } else if($('.test-size').css('font-size') === '4px') {
            screenSize = 'lg';
          }
        }

        function recaptchaResize() {
          if($('.ginput_recaptcha').length) {
            if (screenSize === 'xs' || screenSize === 'md') {
              $(".ginput_recaptcha").css({
                "transform": "scale(0.8)",
                "-webkit-transform": "scale(0.8)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            } else if (screenSize === 'lg') {
              $(".ginput_recaptcha").css({
                "transform": "scale(0.95)",
                "-webkit-transform": "scale(0.95)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            } else {
              $(".ginput_recaptcha").css({
                "transform": "scale(1)",
                "-webkit-transform": "scale(1)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            }
          }
        }
        $(document).ready(function() {
            checkSize();
            recaptchaResize();
        });

        $(window).resize(function() {
            checkSize();
            recaptchaResize();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



    function initMap() {

      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      var isDraggable = w > 480 ? true : false;
      // Create the map.
      var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 8,
        scrollwheel: false,
        draggable: isDraggable,
        center: {lat: 44.8012, lng: -68.7778},
        mapTypeId: google.maps.MapTypeId.CITY
      });

      // Construct the circle for each value in citymap.
      // Note: We scale the area of the circle based on the population.

        // Add the circle for this city to the map.
      var bangorCircle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: map,
        center: {lat: 44.8012, lng: -68.7778},
        radius: 42186.9
      });

      var center;
      function calculateCenter() {
        center = map.getCenter();
      }
      google.maps.event.addDomListener(map, 'idle', function() {
        calculateCenter();
      });
      google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(center);
      });

    }
